import {
    BlendModeOptions,
    BorderStyleOptions,
    MeasurePointOptions,
    MediaPositionOptions,
    MediaRepeatOptions,
    MediaSizeOptions,
    OverflowOptions,
    PaddingUnitOptions,
    PositionOptions,
    Properties,
    ResizeOptions,
    ShadowStyleOptions,
    SizeUnitOptions
} from 'components/template-designer/types/layerProperties.type';
import { defaultBlackColorSimple, defaultColor } from './default-color';

const defaultProperties: Properties = {
    canEdit: {
        draggable: false,
        resizable: false,
        draggableX: false,
        draggableY: false,
        resizableLocked: false
    },
    customClassname: '',
    measurePoint: MeasurePointOptions.NW,
    horizontalAlign: null,
    verticalAlign: null,
    width: { value: 100, unit: SizeUnitOptions.Px, resize: ResizeOptions.Fixed },
    height: { value: 100, unit: SizeUnitOptions.Px, resize: ResizeOptions.Fixed },
    maxWidth: { value: '', unit: SizeUnitOptions.Px },
    maxHeight: { value: '', unit: SizeUnitOptions.Px },
    scale: 1,
    x: { value: 0, unit: SizeUnitOptions.Px },
    y: { value: 0, unit: SizeUnitOptions.Px },
    lockedSize: false,
    rotation: 0,
    skew: { xOffset: 0, yOffset: 0 },
    transformOrigin: { xOffset: 50, yOffset: 50 },
    background: defaultColor,
    backgroundImage: {
        src: false,
        size: MediaSizeOptions.Cover,
        position: MediaPositionOptions.CenterCenter,
        repeat: MediaRepeatOptions.NoRepeat
    },
    customCSS: '',
    padding: {
        left: { value: 0, unit: PaddingUnitOptions.Px },
        top: { value: 0, unit: PaddingUnitOptions.Px },
        right: { value: 0, unit: PaddingUnitOptions.Px },
        bottom: { value: 0, unit: PaddingUnitOptions.Px }
    },
    margin: {
        left: { value: 0, unit: SizeUnitOptions.Px },
        top: { value: 0, unit: SizeUnitOptions.Px },
        right: { value: 0, unit: SizeUnitOptions.Px },
        bottom: { value: 0, unit: SizeUnitOptions.Px }
    },
    opacity: 1,
    blendMode: BlendModeOptions.Normal,
    display: true,
    borderRadius: {
        topLeft: { value: 0, unit: SizeUnitOptions.Px },
        topRight: { value: 0, unit: SizeUnitOptions.Px },
        bottomRight: { value: 0, unit: SizeUnitOptions.Px },
        bottomLeft: { value: 0, unit: SizeUnitOptions.Px }
    },
    border: {
        borderWidth: {
            left: { value: 1, unit: SizeUnitOptions.Px },
            right: { value: 1, unit: SizeUnitOptions.Px },
            top: { value: 1, unit: SizeUnitOptions.Px },
            bottom: { value: 1, unit: SizeUnitOptions.Px }
        },
        borderColor: defaultBlackColorSimple,
        borderStyle: BorderStyleOptions.None
    },
    shadow: {
        shadowStyle: ShadowStyleOptions.None,
        color: defaultBlackColorSimple,
        xOffset: 4,
        yOffset: 4,
        blur: 4,
        spread: 0
    },
    overflow: OverflowOptions.Visible,
    position: PositionOptions.Absolute
};

export { defaultProperties };
