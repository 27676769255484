import Translation from 'components/data/Translation';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedLoopAnimation, PredefinedScaleAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedScaleAnimation['value'] & PredefinedLoopAnimation['value']; easing: PredefinedScaleAnimation['easing'] } = {
    value: {
        scale: {
            value: 1.1
        },
        loop: {
            value: 0
        }
    },
    easing: {
        type: 'ease-in-out',
        value: 'ease-in-out'
    }
};

const heartbeat: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.heartbeat', 'template-designer'),
    key: 'heartbeat',
    duration: 1,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: false },
    stamps: {
        position: [],
        size: [],
        scale: [
            {
                value: {
                    value: 'initial',
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 1
                },
                stamp: 0.3,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 0.9
                },
                stamp: 0.5,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 1
                },
                stamp: 0.7,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 'initial'
                },
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            }
        ],
        rotation: [],
        opacity: [],
        backgroundColor: [],
        color: [],
        shadow: []
    }
};

export default heartbeat;
