import {
    ContainerProperties,
    FontSizeUnitOptions,
    ResizeOptions,
    ShadowStyleOptions,
    SizeUnitOptions,
    TextAlignHorizontalOptions,
    TextAlignVerticalOptions,
    TextBorderStyleOptions,
    TextDecorationOptions,
    TextDecorationStyleOptions,
    TextProperties,
    TextTransformOptions
} from 'components/template-designer/types/layerProperties.type';
import Translation from 'components/data/Translation';
import { defaultProperties } from './default-properties';
import { defaultColor, defaultBlackColorSimple, defaultTransparent } from './default-color';
import { defaultContainerProperties } from './container-properties';
import { webSafeFonts } from '../fonts';

const defaultTextProperties: TextProperties = {
    ...defaultProperties,
    text: '',
    textBackground: false,
    background: defaultTransparent,
    width: {
        value: '',
        unit: SizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: SizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    textStyling: {
        normal: {
            color: defaultColor,
            fontSize: {
                value: 4.3,
                unit: FontSizeUnitOptions.Vw
            },
            lineHeight: 1.2,
            letterSpacing: 0,
            fontFamily: webSafeFonts[0].value,
            fontSource: 'standard',
            fontVariant: webSafeFonts[0].variants[0].value,
            textTransform: TextTransformOptions.None,
            textAlignHorizontal: TextAlignHorizontalOptions.Left,
            textAlignVertical: TextAlignVerticalOptions.FlexStart,
            textDecoration: TextDecorationOptions.None,
            textDecorationStyle: TextDecorationStyleOptions.Solid,
            textShadow: {
                shadowStyle: ShadowStyleOptions.None,
                color: defaultBlackColorSimple,
                xOffset: 4,
                yOffset: 4,
                blur: 4
            },
            textBorder: {
                borderStyle: TextBorderStyleOptions.None,
                borderWidth: { value: 1, unit: FontSizeUnitOptions.Px },
                color: defaultBlackColorSimple
            }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        highlighted: {
            placeholder: true
        }
    },
    canEdit: {
        ...defaultProperties.canEdit,
        scaleText: false
    }
};

const defaultTextContainerProperties: ContainerProperties = {
    ...defaultContainerProperties,
    smartLayout: true,
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const headlineProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.headline', 'template-designer'),
    background: defaultTransparent,
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            fontVariant: 'bold',
            fontSize: {
                value: 7,
                unit: FontSizeUnitOptions.Vw
            }
        }
    }
};

const sublineProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.subline', 'template-designer'),
    background: defaultTransparent,
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            fontVariant: 'bold',
            fontSize: {
                value: 5,
                unit: FontSizeUnitOptions.Vw
            }
        }
    }
};

const bodyProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.body', 'template-designer'),
    background: defaultTransparent,
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            fontSize: {
                value: 4.3,
                unit: FontSizeUnitOptions.Vw
            }
        }
    }
};

const smallBodyProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.small_body_text', 'template-designer'),
    background: defaultTransparent,
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            fontSize: {
                value: 3.8,
                unit: FontSizeUnitOptions.Vw
            }
        }
    }
};

const overlineProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.overline', 'template-designer'),
    background: defaultTransparent,
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            textTransform: TextTransformOptions.Uppercase,
            fontSize: {
                value: 3.7,
                unit: FontSizeUnitOptions.Vw
            }
        }
    }
};

const captionProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.caption', 'template-designer'),
    background: defaultTransparent,
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            fontSize: {
                value: 3.3,
                unit: FontSizeUnitOptions.Vw
            }
        }
    }
};

export {
    defaultTextProperties,
    defaultTextContainerProperties,
    headlineProperties,
    sublineProperties,
    bodyProperties,
    smallBodyProperties,
    overlineProperties,
    captionProperties
};
