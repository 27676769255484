import FormatProperties, { LayoutGridOptions } from 'components/template-designer/types/formatProperties.type';
import {
    BorderStyleOptions,
    FontSizeUnitOptions,
    MediaPositionOptions,
    MediaRepeatOptions,
    MediaSizeOptions
} from 'components/template-designer/types/layerProperties.type';
import { ColorOptions } from 'components/template-designer/types/color.type';
import { defaultWhiteColor } from './default-color';

const defaultFormatProperties: FormatProperties = {
    background: defaultWhiteColor,
    backgroundImage: {
        src: false, // false || {source object}
        size: MediaSizeOptions.Cover,
        position: MediaPositionOptions.CenterCenter,
        repeat: MediaRepeatOptions.NoRepeat
    },
    border: {
        borderWidth: {
            left: { value: 1, unit: FontSizeUnitOptions.Px },
            right: { value: 1, unit: FontSizeUnitOptions.Px },
            top: { value: 1, unit: FontSizeUnitOptions.Px },
            bottom: { value: 1, unit: FontSizeUnitOptions.Px }
        },
        borderColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } },
        borderStyle: BorderStyleOptions.None
    },
    textStyling: {
        normal: {
            fontSize: { value: 16, unit: FontSizeUnitOptions.Px }
        }
    },
    customCSS: '',
    hover: {
        transition: {
            timingFunction: 'ease-in',
            duration: 0, // ms
            delay: 0 // ms
        },
        properties: {
            background: {
                type: ColorOptions.Solid,
                rotation: 90,
                points: [
                    { location: 0, color: { hex: '#ff0000', rgb: { r: 255, g: 0, b: 0, a: 1.0 } } },
                    { location: 1, color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } }
                ]
            },
            opacity: 1,
            borderColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } },
            enableProperties: {}
        }
    },
    layoutGrid: {
        activeLayout: LayoutGridOptions.Guidelines
    }
};

export { defaultFormatProperties };
