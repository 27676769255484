import Translation from 'components/data/Translation';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedPositionAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedPositionAnimation['value']; easing: PredefinedPositionAnimation['easing'] } = {
    value: {
        position: {
            y: { value: 50, unit: 'px' },
            direction: 'right',
            directionDegrees: 0
        }
    },
    easing: {
        type: 'ease-in',
        value: 'ease-in'
    }
};

const slideOut: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.slideOut', 'template-designer'),
    key: 'slideOut',
    duration: 0.75,
    defaultValue: defaultValue,
    reset: { fadeIn: true, fadeOut: false },
    stamps: {
        position: [
            {
                value: {
                    y: {
                        value: 0,
                        unit: '%'
                    }
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-in',
                    value: 'ease-in'
                }
            },
            {
                value: {
                    y: {
                        value: 100,
                        unit: '%'
                    }
                },
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'ease-in',
                    value: 'ease-in'
                }
            }
        ],
        size: [],
        scale: [],
        rotation: [],
        opacity: [
            {
                value: 1,
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-in',
                    value: 'ease-in'
                }
            },
            {
                value: 1,
                stamp: 0.4,
                id: generateKey(),
                easing: {
                    type: 'ease-in',
                    value: 'ease-in'
                }
            },
            {
                value: 0,
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'ease-in',
                    value: 'ease-in'
                }
            }
        ],
        backgroundColor: [],
        color: [],
        shadow: []
    }
};

export default slideOut;
