import {
    SmartLayoutAlignOptions,
    SmartLayoutDirectionOptions,
    SmartLayoutJustifyOptions,
    ContainerProperties,
    FontSizeUnitOptions,
    ResizeOptions
} from 'components/template-designer/types/layerProperties.type';
import { ColorOptions } from 'components/template-designer/types/color.type';
import { defaultProperties } from './default-properties';

const defaultContainerProperties: ContainerProperties = {
    ...defaultProperties,
    smartLayout: false,
    background: {
        type: ColorOptions.Transparent,
        rotation: 0,
        points: [
            { location: 0, color: { hex: '#515a6a', rgb: { r: 81, g: 90, b: 106, a: 0 } } },
            { location: 1, color: { hex: '#515a6a', rgb: { r: 81, g: 90, b: 106, a: 0 } } }
        ]
    },
    direction: SmartLayoutDirectionOptions.Column,
    align: SmartLayoutAlignOptions.FlexStart,
    justify: SmartLayoutJustifyOptions.FlexStart,
    columnGap: { value: 0, unit: FontSizeUnitOptions.Px },
    rowGap: { value: 0, unit: FontSizeUnitOptions.Px },
    width: { value: 100, unit: FontSizeUnitOptions.Px, resize: ResizeOptions.Fixed },
    height: { value: 100, unit: FontSizeUnitOptions.Px, resize: ResizeOptions.Fixed }
};

export { defaultContainerProperties };
