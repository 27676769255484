import Translation from 'components/data/Translation';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedRotationAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedRotationAnimation['value']; easing: PredefinedRotationAnimation['easing'] } = {
    value: {
        rotation: {
            value: 90,
            transformOrigin: {
                xOffset: 50,
                yOffset: 50
            },
            direction: 'right'
        }
    },
    easing: {
        type: 'ease-out',
        value: 'ease-out'
    }
};

const rotateIn: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.rotateIn', 'template-designer'),
    key: 'rotateIn',
    duration: 0.75,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: true },
    stamps: {
        position: [],
        size: [],
        scale: [
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 0.5
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 1
                },
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            }
        ],
        rotation: [
            {
                value: {
                    value: -90,
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    value: 0,
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            }
        ],
        opacity: [
            {
                value: 0,
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: 1,
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            }
        ],
        backgroundColor: [],
        shadow: [],
        color: []
    }
};

export default rotateIn;
