import { AudioProperties, MediaPositionOptions, MediaRepeatOptions, MediaSizeOptions } from 'components/template-designer/types/layerProperties.type';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const defaultAudioProperties: AudioProperties = {
    src: null,
    media: {
        position: MediaPositionOptions.CenterCenter,
        size: MediaSizeOptions.Contain,
        repeat: MediaRepeatOptions.NoRepeat,
        src: false
    },
    volume: 1,
    loop: false
};

export { defaultAudioProperties };
