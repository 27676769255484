import Translation from 'components/data/Translation';
import { PredefinedPositionAnimation } from 'components/template-designer/types/animation.type';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedPositionAnimation['value']; easing: PredefinedPositionAnimation['easing'] } = {
    value: {
        position: {
            y: { value: 200, unit: 'px' },
            direction: 'right',
            directionDegrees: 0
        }
    },
    easing: {
        type: 'ease-in-out',
        value: 'ease-in-out'
    }
};

const bungee: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.backIn', 'template-designer'),
    key: 'backIn',
    duration: 1.5,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: true },
    stamps: {
        position: [
            {
                value: {
                    x: {
                        value: 0,
                        unit: '%'
                    },
                    y: {
                        value: -100,
                        unit: '%'
                    }
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: {
                    x: {
                        value: 0,
                        unit: '%'
                    },
                    y: {
                        value: 0,
                        unit: '%'
                    }
                },
                stamp: 0.7,
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            }
        ],
        size: [],
        scale: [
            {
                stamp: 0,
                value: {
                    value: 0.7,
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                stamp: 0.6,
                value: {
                    value: 0.7,
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                stamp: 0.7,
                value: {
                    value: 0.7,
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                stamp: 1,
                value: {
                    value: 1,
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    }
                },
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            }
        ],
        rotation: [],
        opacity: [
            {
                value: 0,
                stamp: 0,
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: 0.5,
                stamp: 0.1,
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: 0.5,
                stamp: 0.7,
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            },
            {
                value: 1,
                stamp: 1,
                id: generateKey(),

                easing: {
                    type: 'ease-in-out',
                    value: 'ease-in-out'
                }
            }
        ],
        backgroundColor: [],
        color: [],
        shadow: []
    }
};

export default bungee;
