import LayerProperties from 'components/template-designer/types/layerProperties.type';
import { defaultProperties } from './default-properties';
import { defaultHover } from './default-hover';

const defaultLayerProperties: LayerProperties = {
    animations: {},
    hover: defaultHover,
    properties: defaultProperties,
    visibility: [0, 1]
};

export { defaultLayerProperties };
