import {
    ImageProperties,
    MediaPositionOptions,
    MediaRepeatOptions,
    MediaSizeOptions,
    OverflowOptions,
    ResizeOptions,
    ShadowPlacementOptions
} from 'components/template-designer/types/layerProperties.type';
import { defaultProperties } from './default-properties';
import { defaultTransparent } from './default-color';

const defaultImageProperties: ImageProperties = {
    ...defaultProperties,
    background: defaultTransparent,
    overflow: OverflowOptions.Hidden,
    shadowPlacement: ShadowPlacementOptions.Box,
    media: {
        position: MediaPositionOptions.CenterCenter,
        size: MediaSizeOptions.Contain,
        repeat: MediaRepeatOptions.NoRepeat,
        src: false
    },
    canEdit: {
        ...defaultProperties.canEdit,
        defaultHidden: false
    }
};

const backgroundImageProperties: ImageProperties = {
    ...defaultImageProperties,
    width: {
        value: 100,
        unit: '%',
        resize: ResizeOptions.Fill
    },
    height: {
        value: 100,
        unit: '%',
        resize: ResizeOptions.Fill
    },
    media: {
        ...defaultImageProperties.media,
        position: MediaPositionOptions.CenterCenter,
        size: MediaSizeOptions.Cover
    }
};

const logoImageProperties: ImageProperties = {
    ...defaultImageProperties,
    x: {
        value: 16,
        unit: 'px'
    },
    y: {
        value: 16,
        unit: 'px'
    },
    width: {
        value: '',
        unit: 'px',
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 40,
        unit: 'px',
        resize: ResizeOptions.Fixed
    },
    media: {
        ...defaultImageProperties.media,
        position: MediaPositionOptions.CenterCenter,
        size: MediaSizeOptions.Contain
    }
};

export { defaultImageProperties, backgroundImageProperties, logoImageProperties };
