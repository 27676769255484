import Translation from 'components/data/Translation';
import WebSafeFont from '../types/webSafeFont.type';

export const webSafeFonts: WebSafeFont[] = [
    {
        label: 'Arial',
        value: 'Arial',
        variants: [
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.regular', 'template-designer'), value: 'regular' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.bold', 'template-designer'), value: 'bold' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.italic', 'template-designer'), value: 'italic' }
        ]
    },
    {
        label: 'Verdana',
        value: 'Verdana',
        variants: [
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.regular', 'template-designer'), value: 'regular' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.bold', 'template-designer'), value: 'bold' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.italic', 'template-designer'), value: 'italic' }
        ]
    },
    {
        label: 'Tahoma',
        value: 'Tahoma',
        variants: [
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.regular', 'template-designer'), value: 'regular' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.bold', 'template-designer'), value: 'bold' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.italic', 'template-designer'), value: 'italic' }
        ]
    },
    {
        label: 'Times New Roman',
        value: 'Times New Roman',
        variants: [
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.regular', 'template-designer'), value: 'regular' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.bold', 'template-designer'), value: 'bold' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.italic', 'template-designer'), value: 'italic' }
        ]
    },
    {
        label: 'Georgia',
        value: 'Georgia',
        variants: [
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.regular', 'template-designer'), value: 'regular' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.bold', 'template-designer'), value: 'bold' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.italic', 'template-designer'), value: 'italic' }
        ]
    },
    {
        label: 'Courier',
        value: 'Courier',
        variants: [
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.regular', 'template-designer'), value: 'regular' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.bold', 'template-designer'), value: 'bold' },
            { label: Translation.get('sidebarRight.inputs.fontVariantOptions.italic', 'template-designer'), value: 'italic' }
        ]
    }
];
