import {
    MediaPositionOptions,
    MediaRepeatOptions,
    MediaSizeOptions,
    OverflowOptions,
    ResizeOptions,
    VideoProperties
} from 'components/template-designer/types/layerProperties.type';
import { defaultProperties } from './default-properties';
import { defaultTransparent } from './default-color';

const defaultVideoProperties: VideoProperties = {
    ...defaultProperties,
    background: defaultTransparent,
    overflow: OverflowOptions.Hidden,
    media: {
        position: MediaPositionOptions.CenterCenter,
        size: MediaSizeOptions.Contain,
        repeat: MediaRepeatOptions.NoRepeat,
        src: false
    },
    canEdit: {
        ...defaultProperties.canEdit,
        defaultHidden: false
    },
    hideWhenFinished: false,
    volumeOff: false
};

const backgroundVideoProperties: VideoProperties = {
    ...defaultVideoProperties,
    width: {
        value: 100,
        unit: '%',
        resize: ResizeOptions.Fill
    },
    height: {
        value: 100,
        unit: '%',
        resize: ResizeOptions.Fill
    },
    media: {
        ...defaultVideoProperties.media,
        position: MediaPositionOptions.CenterCenter,
        size: MediaSizeOptions.Cover
    }
};

export { defaultVideoProperties, backgroundVideoProperties };
