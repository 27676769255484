import { BorderRadiusUnitOptions, FontSizeUnitOptions, ResizeOptions, ShapeProperties } from 'components/template-designer/types/layerProperties.type';
import { defaultProperties } from './default-properties';

const rectangleProperties: ShapeProperties = {
    ...defaultProperties,
    width: {
        value: 100,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 100,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    }
};

const rectangleRoundedProperties: ShapeProperties = {
    ...defaultProperties,
    width: {
        value: 100,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 100,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    borderRadius: {
        topLeft: {
            value: 12,
            unit: BorderRadiusUnitOptions['%']
        },
        topRight: {
            value: 12,
            unit: BorderRadiusUnitOptions['%']
        },
        bottomRight: {
            value: 12,
            unit: BorderRadiusUnitOptions['%']
        },
        bottomLeft: {
            value: 12,
            unit: BorderRadiusUnitOptions['%']
        }
    }
};

const circleProperties: ShapeProperties = {
    ...defaultProperties,
    width: {
        value: 100,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 100,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    borderRadius: {
        topLeft: {
            value: 50,
            unit: BorderRadiusUnitOptions['%']
        },
        topRight: {
            value: 50,
            unit: BorderRadiusUnitOptions['%']
        },
        bottomRight: {
            value: 50,
            unit: BorderRadiusUnitOptions['%']
        },
        bottomLeft: {
            value: 50,
            unit: BorderRadiusUnitOptions['%']
        }
    }
};

const lineProperties: ShapeProperties = {
    ...defaultProperties,
    width: {
        value: 100,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 4,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    }
};

export { rectangleProperties, rectangleRoundedProperties, circleProperties, lineProperties };
