import { LottieProperties } from 'components/template-designer/types/layerProperties.type';
import { defaultProperties } from './default-properties';
import { defaultTransparent } from './default-color';

const defaultLottieProperties: LottieProperties = {
    ...defaultProperties,
    background: defaultTransparent,
    lottieAnimation: {
        url: '',
        extension: '',
        title: ''
    }
};

export { defaultLottieProperties };
