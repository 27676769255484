import LayerProperties from 'components/template-designer/types/layerProperties.type';
import { defaultColor } from './default-color';

const defaultHover: LayerProperties['hover'] = {
    transition: {
        timingFunction: 'ease-in',
        duration: 0, // ms
        delay: 0 // ms
    },
    properties: {
        scale: 1,
        translateX: 0,
        translateY: 0,
        rotation: 0,
        opacity: 1,
        background: defaultColor,
        color: defaultColor,
        borderColor: defaultColor,
        enableProperties: {}
    }
};

export { defaultHover };
