import Translation from 'components/data/Translation';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedScaleAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedScaleAnimation['value']; easing: PredefinedScaleAnimation['easing'] } = {
    value: {
        scale: {
            value: 1.2
        }
    },
    easing: {
        type: 'ease-out',
        value: 'ease-out'
    }
};

const popUp: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.popUp', 'template-designer'),
    key: 'popUp',
    duration: 1,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: true },
    stamps: {
        position: [],
        size: [],
        scale: [
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 0
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 1
                },
                stamp: 0.4,
                id: generateKey(),
                easing: {
                    type: 'ease-out',
                    value: 'ease-out'
                }
            },
            {
                value: {
                    transformOrigin: {
                        xOffset: 50,
                        yOffset: 50
                    },
                    value: 'initial'
                },
                stamp: 0.6,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            }
        ],
        rotation: [],
        opacity: [
            {
                value: 0,
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: 1,
                stamp: 0.35,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            }
        ],
        backgroundColor: [],
        color: [],
        shadow: []
    }
};

export default popUp;
