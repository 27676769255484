import Translation from 'components/data/Translation';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { PredefinedPositionAnimation, PredefinedLoopAnimation } from 'components/template-designer/types/animation.type';
import { PredefinedAnimationSetup } from '.';

const defaultValue: { value: PredefinedPositionAnimation['value'] & PredefinedLoopAnimation['value']; easing: PredefinedPositionAnimation['easing'] } = {
    value: {
        position: {
            y: { value: 3, unit: 'px' },
            direction: 'right',
            directionDegrees: 0
        },
        loop: {
            value: 0
        }
    },
    easing: {
        type: 'linear',
        value: 'linear'
    }
};

const shake: PredefinedAnimationSetup = {
    label: Translation.get('animation.predefinedAnimations.animations.shake', 'template-designer'),
    key: 'shake',
    duration: 0.5,
    defaultValue: defaultValue,
    reset: { fadeIn: false, fadeOut: false },
    stamps: {
        position: [
            {
                value: {
                    y: {
                        value: 0,
                        unit: '%'
                    }
                },
                stamp: 0,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    y: {
                        value: 100,
                        unit: '%'
                    }
                },
                stamp: 0.14,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    y: {
                        value: -80,
                        unit: '%'
                    }
                },
                stamp: 0.28,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    y: {
                        value: 80,
                        unit: '%'
                    }
                },
                stamp: 0.42,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    y: {
                        value: -60,
                        unit: '%'
                    }
                },
                stamp: 0.7,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    y: {
                        value: 60,
                        unit: '%'
                    }
                },
                stamp: 0.84,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            },
            {
                value: {
                    y: {
                        value: 0,
                        unit: '%'
                    }
                },
                stamp: 1,
                id: generateKey(),
                easing: {
                    type: 'linear',
                    value: 'linear'
                }
            }
        ],
        size: [],
        scale: [],
        rotation: [],
        opacity: [],
        backgroundColor: [],
        color: [],
        shadow: []
    }
};

export default shake;
