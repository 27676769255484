import {
    BorderStyleOptions,
    ImageProperties,
    PositionOptions,
    SmartLayoutAlignOptions,
    SmartLayoutDirectionOptions,
    SmartLayoutJustifyOptions,
    ContainerProperties,
    TextProperties,
    TextAlignHorizontalOptions,
    TextAlignVerticalOptions,
    FontSizeUnitOptions,
    ResizeOptions,
    PaddingUnitOptions
} from 'components/template-designer/types/layerProperties.type';
import Translation from 'components/data/Translation';
import { defaultTextProperties } from './text-properties';
import { defaultColor, defaultColorSimple, defaultTransparent, defaultWhiteColor } from './default-color';
import { defaultContainerProperties } from './container-properties';
import { defaultImageProperties } from './image-properties';

const BLACK_ICON = 'https://storage.googleapis.com/cape-static-hosting/assets/chevron-right.svg';
const WHITE_ICON = 'https://storage.googleapis.com/cape-static-hosting/assets/chevron-right-white.svg';

const defaultButtonProperties: TextProperties = {
    ...defaultTextProperties,
    background: defaultColor,
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            color: defaultWhiteColor,
            fontSize: { value: 14, unit: FontSizeUnitOptions.Px },
            textAlignHorizontal: TextAlignHorizontalOptions.Center,
            textAlignVertical: TextAlignVerticalOptions.Center
        }
    },
    borderRadius: {
        topLeft: { value: 100, unit: FontSizeUnitOptions.Px },
        topRight: { value: 100, unit: FontSizeUnitOptions.Px },
        bottomRight: { value: 100, unit: FontSizeUnitOptions.Px },
        bottomLeft: { value: 100, unit: FontSizeUnitOptions.Px }
    },
    padding: {
        top: { value: 8, unit: PaddingUnitOptions.Px },
        right: { value: 16, unit: PaddingUnitOptions.Px },
        bottom: { value: 8, unit: PaddingUnitOptions.Px },
        left: { value: 16, unit: PaddingUnitOptions.Px }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonContainedProperties: TextProperties = {
    ...defaultButtonProperties,
    text: Translation.get('general.propertyKeys.button_contained', 'template-designer')
};

const buttonOutlinedProperties: TextProperties = {
    ...defaultButtonProperties,
    text: Translation.get('general.propertyKeys.button_outlined', 'template-designer'),
    background: defaultTransparent,
    textStyling: {
        ...defaultButtonProperties.textStyling,
        normal: {
            ...defaultButtonProperties.textStyling.normal,
            color: defaultColor
        }
    },
    border: {
        borderStyle: BorderStyleOptions.Solid,
        borderWidth: {
            left: { value: 1, unit: FontSizeUnitOptions.Px },
            right: { value: 1, unit: FontSizeUnitOptions.Px },
            top: { value: 1, unit: FontSizeUnitOptions.Px },
            bottom: { value: 1, unit: FontSizeUnitOptions.Px }
        },
        borderColor: defaultColorSimple
    }
};

const buttonTextProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.button_text', 'template-designer'),
    position: PositionOptions.Relative,
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            color: defaultColor
        }
    }
};

const buttonContainedContainerProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultColor,
    smartLayout: true,
    direction: SmartLayoutDirectionOptions.Row,
    justify: SmartLayoutJustifyOptions.FlexStart,
    align: SmartLayoutAlignOptions.Center,
    columnGap: {
        value: 8,
        unit: FontSizeUnitOptions.Px
    },
    borderRadius: {
        topLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        }
    },
    padding: {
        top: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 24,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 24,
            unit: PaddingUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonContainedTextProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.button_contained_arrow', 'template-designer'),
    position: PositionOptions.Relative,
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            color: defaultWhiteColor
        }
    }
};

const buttonContainedArrowImageProperties: ImageProperties = {
    ...defaultImageProperties,
    position: PositionOptions.Relative,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: WHITE_ICON
        }
    },
    width: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

const buttonOutlinedContainerProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultTransparent,
    border: {
        borderStyle: BorderStyleOptions.Solid,
        borderWidth: {
            left: { value: 1, unit: FontSizeUnitOptions.Px },
            right: { value: 1, unit: FontSizeUnitOptions.Px },
            top: { value: 1, unit: FontSizeUnitOptions.Px },
            bottom: { value: 1, unit: FontSizeUnitOptions.Px }
        },
        borderColor: defaultColorSimple
    },
    smartLayout: true,
    direction: SmartLayoutDirectionOptions.Row,
    justify: SmartLayoutJustifyOptions.FlexStart,
    align: SmartLayoutAlignOptions.Center,
    columnGap: {
        value: 8,
        unit: FontSizeUnitOptions.Px
    },
    borderRadius: {
        topLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        }
    },
    padding: {
        top: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 24,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 24,
            unit: PaddingUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonOutlinedTextProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.button_outlined_arrow', 'template-designer'),
    position: PositionOptions.Relative,
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            color: defaultColor
        }
    }
};

const buttonOutlinedArrowImageProperties: ImageProperties = {
    ...defaultImageProperties,
    position: PositionOptions.Relative,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: BLACK_ICON
        }
    },
    width: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

const buttonTextArrowContainerProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultTransparent,
    smartLayout: true,
    direction: SmartLayoutDirectionOptions.Row,
    justify: SmartLayoutJustifyOptions.FlexStart,
    align: SmartLayoutAlignOptions.Center,
    columnGap: {
        value: 8,
        unit: FontSizeUnitOptions.Px
    },
    borderRadius: {
        topLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        }
    },
    padding: {
        top: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 24,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 24,
            unit: PaddingUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonTextArrowImageProperties: ImageProperties = {
    ...defaultImageProperties,
    position: PositionOptions.Relative,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: BLACK_ICON
        }
    },
    width: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

const buttonContainedSquareArrowContainerProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultColor,
    smartLayout: true,
    direction: SmartLayoutDirectionOptions.Row,
    justify: SmartLayoutJustifyOptions.FlexStart,
    align: SmartLayoutAlignOptions.Center,
    columnGap: {
        value: 8,
        unit: FontSizeUnitOptions.Px
    },
    borderRadius: {
        topLeft: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        }
    },
    padding: {
        top: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 24,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 24,
            unit: PaddingUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonContainedSquareArrowTextProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.button_contained_arrow', 'template-designer'),
    position: PositionOptions.Relative,
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            color: defaultWhiteColor
        }
    }
};

const buttonContainedSquareArrowImageProperties: ImageProperties = {
    ...defaultImageProperties,
    position: PositionOptions.Relative,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: WHITE_ICON
        }
    },
    width: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

const buttonOutlinedSquareArrowContainerProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultTransparent,
    border: {
        borderStyle: BorderStyleOptions.Solid,
        borderWidth: {
            left: { value: 1, unit: FontSizeUnitOptions.Px },
            right: { value: 1, unit: FontSizeUnitOptions.Px },
            top: { value: 1, unit: FontSizeUnitOptions.Px },
            bottom: { value: 1, unit: FontSizeUnitOptions.Px }
        },
        borderColor: defaultColorSimple
    },
    smartLayout: true,
    direction: SmartLayoutDirectionOptions.Row,
    justify: SmartLayoutJustifyOptions.FlexStart,
    align: SmartLayoutAlignOptions.Center,
    columnGap: {
        value: 8,
        unit: FontSizeUnitOptions.Px
    },
    borderRadius: {
        topLeft: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 6,
            unit: FontSizeUnitOptions.Px
        }
    },
    padding: {
        top: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 24,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 12,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 24,
            unit: PaddingUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonOutlinedSquareArrowTextProperties: TextProperties = {
    ...defaultTextProperties,
    text: Translation.get('general.propertyKeys.button_outlined_arrow', 'template-designer'),
    position: PositionOptions.Relative,
    textStyling: {
        ...defaultTextProperties.textStyling,
        normal: {
            ...defaultTextProperties.textStyling.normal,
            color: defaultColor
        }
    }
};

const buttonOutlinedSquareArrowImageProperties: ImageProperties = {
    ...defaultImageProperties,
    position: PositionOptions.Relative,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: BLACK_ICON
        }
    },
    width: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 16,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

const buttonContainerArrowContainedProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultColor,
    padding: {
        top: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 8,
            unit: PaddingUnitOptions.Px
        }
    },
    borderRadius: {
        topLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonContainedArrowProperties: ImageProperties = {
    ...defaultImageProperties,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: WHITE_ICON
        }
    },
    width: {
        value: 24,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 24,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

const buttonContainerArrowOutlinedProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultTransparent,
    border: {
        borderStyle: BorderStyleOptions.Solid,
        borderWidth: {
            left: { value: 1, unit: FontSizeUnitOptions.Px },
            right: { value: 1, unit: FontSizeUnitOptions.Px },
            top: { value: 1, unit: FontSizeUnitOptions.Px },
            bottom: { value: 1, unit: FontSizeUnitOptions.Px }
        },
        borderColor: defaultColorSimple
    },
    padding: {
        top: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 8,
            unit: PaddingUnitOptions.Px
        }
    },
    borderRadius: {
        topLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonOutlinedArrowProperties: ImageProperties = {
    ...defaultImageProperties,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: BLACK_ICON
        }
    },
    width: {
        value: 24,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 24,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

const buttonContainerArrowProperties: ContainerProperties = {
    ...defaultContainerProperties,
    background: defaultTransparent,
    padding: {
        top: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        right: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        bottom: {
            value: 8,
            unit: PaddingUnitOptions.Px
        },
        left: {
            value: 8,
            unit: PaddingUnitOptions.Px
        }
    },
    borderRadius: {
        topLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        topRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomRight: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        },
        bottomLeft: {
            value: 100,
            unit: FontSizeUnitOptions.Px
        }
    },
    width: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    },
    height: {
        value: '',
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Hug
    }
};

const buttonArrowProperties: ImageProperties = {
    ...defaultImageProperties,
    media: {
        ...defaultImageProperties.media,
        src: {
            assetGalleryInput: true,
            extension: 'svg',
            fileName: 'chevron_right_FILL0_wght400_GRAD0_opsz48.svg',
            fileType: 'image',
            humanSize: '138Bytes',
            size: 0,
            title: 'Chevron image',
            url: BLACK_ICON
        }
    },
    width: {
        value: 24,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    height: {
        value: 24,
        unit: FontSizeUnitOptions.Px,
        resize: ResizeOptions.Fixed
    },
    lockedSize: true
};

export {
    buttonContainedProperties,
    buttonOutlinedProperties,
    buttonTextProperties,
    buttonContainedContainerProperties,
    buttonContainedTextProperties,
    buttonContainedArrowImageProperties,
    buttonOutlinedContainerProperties,
    buttonOutlinedTextProperties,
    buttonOutlinedArrowImageProperties,
    buttonTextArrowContainerProperties,
    buttonTextArrowImageProperties,
    buttonContainedSquareArrowContainerProperties,
    buttonContainedSquareArrowTextProperties,
    buttonContainedSquareArrowImageProperties,
    buttonOutlinedSquareArrowContainerProperties,
    buttonOutlinedSquareArrowTextProperties,
    buttonOutlinedSquareArrowImageProperties,
    buttonContainerArrowContainedProperties,
    buttonContainedArrowProperties,
    buttonContainerArrowOutlinedProperties,
    buttonOutlinedArrowProperties,
    buttonContainerArrowProperties,
    buttonArrowProperties
};
