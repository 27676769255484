import Translation from 'components/data/Translation';
import { AnimationOptions, TimingFunction } from '../types/animation.type';

const animationOptions: { key: AnimationOptions; label: string; tooltip: string }[] = [
    {
        key: 'position',
        label: Translation.get('timeline.animationOptions.position', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.position', 'template-designer')
    },
    {
        key: 'size',
        label: Translation.get('timeline.animationOptions.size', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.size', 'template-designer')
    },
    {
        key: 'scale',
        label: Translation.get('timeline.animationOptions.scale', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.scale', 'template-designer')
    },
    {
        key: 'rotation',
        label: Translation.get('timeline.animationOptions.rotation', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.rotation', 'template-designer')
    },
    {
        key: 'opacity',
        label: Translation.get('timeline.animationOptions.opacity', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.opacity', 'template-designer')
    },
    {
        key: 'backgroundColor',
        label: Translation.get('timeline.animationOptions.backgroundColor', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.backgroundColor', 'template-designer')
    },
    {
        key: 'color',
        label: Translation.get('timeline.animationOptions.color', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.color', 'template-designer')
    },
    {
        key: 'shadow',
        label: Translation.get('timeline.animationOptions.shadow', 'template-designer'),
        tooltip: Translation.get('timeline.animationOptions.tooltip.shadow', 'template-designer')
    }
];

const animationEasingOptions: { key: TimingFunction; label: string }[] = [
    { key: 'ease', label: Translation.get('timeline.animationEasingOptions.ease', 'template-designer') },
    { key: 'ease-in', label: Translation.get('timeline.animationEasingOptions.ease-in', 'template-designer') },
    { key: 'ease-out', label: Translation.get('timeline.animationEasingOptions.ease-out', 'template-designer') },
    { key: 'ease-in-out', label: Translation.get('timeline.animationEasingOptions.ease-in-out', 'template-designer') },
    { key: 'linear', label: Translation.get('timeline.animationEasingOptions.linear', 'template-designer') },
    { key: 'custom', label: Translation.get('timeline.animationEasingOptions.custom', 'template-designer') }
];

export { animationOptions, animationEasingOptions };
