import { ColorOptions } from 'components/template-designer/types/color.type';
import { LayerHover } from 'components/template-designer/types/hover.type';

const defaultHoverProperties: LayerHover = {
    transition: {
        timingFunction: 'ease-in',
        duration: 0, // ms
        delay: 0 // ms
    },
    properties: {
        scale: 1,
        translateX: 0,
        translateY: 0,
        rotation: 0,
        opacity: 1,
        background: {
            type: ColorOptions.Solid,
            rotation: 90,
            points: [
                { location: 0, color: { hex: '#ff0000', rgb: { r: 256, g: 0, b: 0, a: 1.0 } } },
                { location: 1, color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } }
            ]
        },
        color: {
            type: ColorOptions.Solid,
            rotation: 90,
            points: [
                { location: 0, color: { hex: '#ff0000', rgb: { r: 256, g: 0, b: 0, a: 1.0 } } },
                { location: 1, color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } }
            ]
        },
        borderColor: {
            type: ColorOptions.Solid,
            rotation: 90,
            points: [
                { location: 0, color: { hex: '#ff0000', rgb: { r: 256, g: 0, b: 0, a: 1.0 } } },
                { location: 1, color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } } }
            ]
        },
        enableProperties: {}
    }
};

export { defaultHoverProperties };
